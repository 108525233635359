import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "components/molecules/Layout"

import ProductDetails from "components/organisms/ProductDetails"
import BestThingAboutProduct from "components/organisms/BestThingAboutProduct"
import JustGiveMeTheFacts from "components/organisms/JustGiveMeTheFacts"
import ProductHowDoWeStackUp from "components/organisms/ProductHowDoWeStackUp"
import Advertisement from "components/organisms/Advertisement"
import CustomerReviews from "components/organisms/CustomerReviews"
import FaqSection from "components/organisms/FaqSection"
import AskUsAnythingForMobile from "components/organisms/AskUsAnythingForMobile"
import WhatToMakeSection from "components/organisms/WhatToMakeSection"
import RealReviewSection from "components/organisms/RealReviewSection"
import SignUpSection from "components/organisms/SignUpSection"
import GraphQLErrorList from "components/atoms/Error/graphql-error-list"
import SEO from "components/atoms/SEO"
import { loadStamped } from "../../utils/stamped"
import useBlackFriday from "hooks/useBlackFriday"
import { useZendeskShow } from "hooks/useZendesk"

export const query = graphql`
  query ProductTemplateQuery($id: String!, $shopifyProductId: String!) {
    product: sanityProduct(id: { eq: $id }) {
      ...Product
    }
    productShopify: shopifyProduct(legacyResourceId: { eq: $shopifyProductId }) {
      variants {
        sku
        storefrontId
        shopifyId
        inventoryQuantity
        price
        title
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          id
          products {
            id
            shopifyProductId
          }
          title
        }
      }
    }
  }
`

const ProductPage = props => {
  const { data, errors, pageContext } = props
  const shopifyVariants = data?.productShopify?.variants
  const { product, categories } = data
  const activeFlavor = pageContext?.activeFlavor
  const { enableDiscount } = useBlackFriday()

  product.categories = categories.edges
    .map(c => c.node)
    .filter(c => c.products?.some(p => p.shopifyProductId === product.shopifyProductId))
    .map(c => c.title)

  useEffect(() => {
    loadStamped()
  })

  useZendeskShow()

  // enable or disable discounting for product
  useEffect(() => {
    if (enableDiscount) {
      // product.flavors.forEach(allowDiscountItem => {
      //   allowDiscountItem.allowDiscount = true
      // })
    } else {
      product.flavors.forEach(allowDiscountItem => {
        allowDiscountItem.allowDiscount = false
      })
    }
  }, [])

  return (
    <Layout>
      {errors && (
        <>
          <h2>GraphQL Error</h2>
          <GraphQLErrorList errors={errors} />
        </>
      )}

      {product && (
        <>
          <SEO
            title={product.meta?.metaTitle}
            description={product.meta?.metaDescription}
            canonical={product._rawSlug.current}
          />
          <ProductDetails product={product} shopifyVariants={shopifyVariants} activeFlavor={activeFlavor} />
          {product._rawContentBlocks &&
            product._rawContentBlocks.map((content, idx) => (
              <div key={idx}>
                {content._type === "productHero" && <BestThingAboutProduct content={content} />}
                {content._type === "productFactsBlock" && (
                  <JustGiveMeTheFacts content={content} flavors={product?.flavors} />
                )}
                {content._type === "productStackUpBlock" && <ProductHowDoWeStackUp content={content} />}
                {content._type === "logosBlock" && <Advertisement content={content} />}
                {content._type === "reviewVideoBlock" && <CustomerReviews content={content} />}
                {content._type === "faqSection" && <FaqSection content={content} />}
                {content._type === "productsHowToMakeBlock" && <WhatToMakeSection content={content} />}
              </div>
            ))}
          <AskUsAnythingForMobile />
          <RealReviewSection productId={product?.shopifyProductId} />
          <SignUpSection />
        </>
      )}
    </Layout>
  )
}

export default ProductPage
